import { useMemo } from 'react';
import { differenceInDays, formatDate } from 'date-fns';
import { useSelector } from 'react-redux';

import { ArticleData, SavingsAPIDataV2 } from 'clipsal-cortex-types/src/api';
import { Activity, ActivityAction } from 'clipsal-cortex-types/src/api/api-activities';
import { getStringifiedQueryParams } from 'clipsal-cortex-utils/src/formatting/query-params';

import { baseApi } from '../../app/services/baseApi';
import { DEVICE_TYPE_TO_DISPLAY_DATA } from '../profile/system-details/constants';
import { useGetSiteSwitches } from '../scheduler/schedulerApi';
import { selectSite } from '../site/siteSlice';

type SavingsQueryParams = {
  cumulative: boolean;
  start_date: string;
  end_date: string;
  group_by: 'day' | 'week' | 'month';
};

type ActivityLogQueryParams = {
  date: string;
};

export const dashboardApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getArticles: build.query<ArticleData[], void>({
      query: () => `/v1/articles`,
      providesTags: ['Articles'],
    }),
    getSavings: build.query<SavingsAPIDataV2[], { siteId: number; params: SavingsQueryParams }>({
      query: ({ siteId, params }) => {
        const stringifiedParams = params ? getStringifiedQueryParams<string>(params) : '';
        return `/v2/sites/${siteId}/savings${stringifiedParams ? '?' : ''}${stringifiedParams}`;
      },
      providesTags: (_, __, args) => [{ type: 'Savings', ...args }],
    }),
    getActivityLog: build.query<Activity[], { siteId: number; params: ActivityLogQueryParams }>({
      query: ({ siteId, params }) => {
        const stringifiedParams = params ? getStringifiedQueryParams<string>(params) : '';
        return `/v1/sites/${siteId}/activity${stringifiedParams ? '?' : ''}${stringifiedParams}`;
      },
      providesTags: (_, __, args) => [{ type: 'Activities', ...args }],
    }),
  }),
});

export const { useGetArticlesQuery, useGetSavingsQuery, useGetActivityLogQuery } = dashboardApi;

export const INITIAL_TOTAL_SAVINGS = {
  reading_date: 0,
  energy_system: 0,
  flexible_appliances: 0,
  total_saved: 0,
};

const DAYS_FOR_MONTHLY_VIEW = 365 * 5; // for now site older than 5 years will have monthly view
const DAYS_FOR_WEEKLY_VIEW = 365; // for now site older than 1 year will have weekly view

export const useTotalSavings = (args: { startDate?: string; endDate?: string; skip?: boolean } = {}) => {
  const { site_id: siteId, monitoring_start: monitoringStart } = useSelector(selectSite);

  const { startDateToUse, endDateToUse } = useMemo(() => {
    return {
      startDateToUse: args?.startDate ?? monitoringStart,
      endDateToUse: args?.endDate ?? formatDate(new Date(), 'yyyy-MM-dd'),
    };
  }, [args, monitoringStart]);

  const groupBy = useMemo(() => {
    const differenceInDaysBetweenDates = differenceInDays(new Date(endDateToUse), new Date(startDateToUse));
    if (differenceInDaysBetweenDates > DAYS_FOR_MONTHLY_VIEW) return 'month';
    if (differenceInDaysBetweenDates > DAYS_FOR_WEEKLY_VIEW) return 'week';
    return 'day';
  }, [endDateToUse, startDateToUse]);

  return useGetSavingsQuery(
    {
      siteId,
      params: {
        cumulative: true,
        start_date: startDateToUse,
        end_date: endDateToUse,
        group_by: groupBy,
      },
    },
    {
      skip: args?.skip,
    }
  );
};

export const VARIABLE_ACTIONS: ActivityAction[] = ['SET_AC_STATE'];
export const ON_ACTIONS: ActivityAction[] = ['SWITCH_ON', 'START_CHARGE'];
export const OFF_ACTIONS: ActivityAction[] = ['SWITCH_OFF', 'PAUSE_CHARGE', 'RESUME_CHARGE', 'STOP_CHARGE'];

export type EventType = 'ON' | 'OFF' | 'N/A';

/**
 * @param action - what action is being performed
 * @param actionBody - details of the action
 * @returns - The resulting event type - ON, OFF, or N/A
 */
export const getEventTypeFromAction = ({ action, action_body: actionBody }: Activity): EventType => {
  if (ON_ACTIONS.includes(action)) {
    return 'ON';
  } else if (OFF_ACTIONS.includes(action)) {
    return 'OFF';
  } else if (VARIABLE_ACTIONS.includes(action)) {
    return actionBody.on ? 'ON' : 'OFF';
  }
  return 'N/A';
};

export const useActivityLog = (date: Date) => {
  const { site_id: siteId, devices } = useSelector(selectSite);
  const { switches, isSwitchesLoading } = useGetSiteSwitches();
  const response = useGetActivityLogQuery({ siteId, params: { date: formatDate(date, 'yyyy-MM-dd') } });

  const ActivityLog = useMemo(
    () =>
      (response?.data ?? [])
        .map((activity) => {
          const siteDevice = devices.find((device) => device.id === activity.site_device_id);
          const deviceType = siteDevice?.device_type;
          const siteSwitch = switches.find((s) => s.id === activity.site_device_id);
          return {
            siteDeviceId: activity.site_device_id,
            date: activity.time,
            eventType: getEventTypeFromAction(activity),
            manufacturerId: siteDevice?.manufacturer_id ?? siteSwitch?.manufacturer?.manufacturer_id ?? 0,
            deviceName:
              deviceType && deviceType in DEVICE_TYPE_TO_DISPLAY_DATA
                ? DEVICE_TYPE_TO_DISPLAY_DATA[deviceType].title
                : siteSwitch?.site_switch_label || 'Other',
            deviceType: deviceType ?? '',
            circuitType: siteSwitch?.appliance_label?.split('__')[0] ?? '',
          };
        })
        .filter(({ eventType, deviceType, circuitType }) => {
          // Filter out activities that don't have a device type or circuit type
          // Filter out activities that have an unknown event type
          const hasDeviceOrCircuitType = !!deviceType || !!circuitType;
          return eventType !== 'N/A' && hasDeviceOrCircuitType;
        }),
    [devices, response?.data, switches]
  );

  return {
    ...response,
    ActivityLog,
    isLoading: response.isLoading || isSwitchesLoading,
  };
};
