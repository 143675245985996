import {
  CIRCUIT_TYPE_TO_ICON,
  MANUFACTURER_ID_TO_EV_CHARGER_ICON,
} from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';

import { useLiveData } from '../liveDataApi';

/**
 * Discerns whether certain paths in the animation should be rendered according to the property values in the live
 * data response.
 *
 * @returns An object containing boolean flag properties indicating whether each individual path should be rendered.
 */
export function useTilePathRenderOptions() {
  const { data: liveData, isLoading } = useLiveData({ shouldPoll: false });
  const shouldRenderGridPath = !isLoading && liveData.grid !== 0;
  const shouldRenderBatteryPath = !isLoading && !!liveData.battery && liveData.battery !== 0;
  const shouldRenderSolarBatteryPath = !isLoading && !!liveData.hybrid_inverter && liveData.hybrid_inverter !== 0;
  const shouldRenderSolarPath = !isLoading && liveData.hasOwnProperty('solar') && liveData.solar > 0;
  const shouldRenderHomePath = !isLoading && liveData.consumption !== 0;

  return {
    shouldRenderGridPath,
    shouldRenderBatteryPath,
    shouldRenderSolarBatteryPath,
    shouldRenderSolarPath,
    shouldRenderHomePath,
  };
}

/**
 * Returns the icon for a given circuit type.
 *
 * @param circuitType - The circuit type.
 * @param manufacturerId - The manufacturer ID of the device.
 * @returns The icon for the circuit type.
 */
export function getApplianceIcon(circuitType: string, manufacturerId?: number) {
  // Show actual device if it's an EV charger known to the system
  if (circuitType === 'load_ev_charger' || circuitType === 'load_ev') {
    return manufacturerId
      ? MANUFACTURER_ID_TO_EV_CHARGER_ICON[manufacturerId]
      : CIRCUIT_TYPE_TO_ICON['load_ev_charger'];
  }
  return CIRCUIT_TYPE_TO_ICON[circuitType] ?? CIRCUIT_TYPE_TO_ICON['load_other'];
}
