import './common/types/pwa-register.d.ts';
import './common/types/global.d.ts';
import './common/extended-modules/highcharts';
import 'focus-visible/dist/focus-visible'; // Removes blue border when clicking focusable items
import 'react-app-polyfill/stable';

import React from 'react';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { createRoutes } from './common/components/route/createRoutes';
import { IS_PRODUCTION_BUILD, IS_RUNNING_CYPRESS_TESTS } from './common/constants';
import { AppProvider } from './Provider';
import { routes } from './routes';
import { setupAPI } from './utils/common/setup-api';
import { setupSentry } from './utils/common/setup-sentry';

/**
 * Initialises the react application. Handles setup for the following top-level systems:
 *
 * - Sentry (error and performance tracking)
 * - Google Analytics (analytics tracking)
 * - Amplify (API automation system from AWS)
 *
 * Finally, the root DOM node (<App />) is rendered.
 */
async function setupReactDOM() {
  // Only send events to sentry in staging and production environments
  /* istanbul ignore next -- @preserve */
  if (import.meta.env?.['MODE'] !== 'development' && !IS_RUNNING_CYPRESS_TESTS) setupSentry();

  // This is only here to prompt for user tracking permissions on app launch, then the result is used elsewhere
  // (i.e. when a user is logged in). Just ensures the prompt occurs on app launch, rather than at login time.
  /* istanbul ignore next -- @preserve */
  if (IS_PRODUCTION_BUILD && Capacitor.getPlatform() === 'ios') {
    await AppTrackingTransparency.requestPermission();
  }

  setupAPI();

  const container = document.getElementById('root');
  const root = createRoot(container!);

  const reactRoutes = createRoutes(routes);
  const router = createBrowserRouter([{ element: <AppProvider />, children: reactRoutes }]);

  // eslint-disable-next-line react/jsx-no-undef
  root.render(<RouterProvider router={router} />);
}

setupReactDOM();
