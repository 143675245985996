import React, { useMemo } from 'react';
import { Box, Center, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { IoCheckmark } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';

import { getApplianceIcon } from '../../live/power-flow/utils';
import { useGetSiteSwitches } from '../../scheduler/schedulerApi';
import { selectSite } from '../../site/siteSlice';
import { selectSmartSaveDevices, SmartSaveDevice as SmartSaveDeviceType } from './smartSaveSlice';
import { useUpdateSmartSaveDevice } from './use-update-smartsave-device';

const SmartSaveDevices = () => {
  const smartSaveDevices = useSelector(selectSmartSaveDevices);
  const background = useColorModeValue('white', 'dusk100.800');

  if (!smartSaveDevices.length) return null;

  return (
    <Box>
      <Text px={4} fontWeight={500} mt={6}>
        SELECTED DEVICES TO OPTIMISE:
      </Text>
      <HStack columnGap={6} bg={background} p={4} mt={2} minH={'96px'} data-testid="smart-save-device-list">
        {smartSaveDevices.map((device, index) => (
          <SmartSaveDevice device={device} key={device.site_device_id} deviceIndex={index} />
        ))}
      </HStack>
    </Box>
  );
};

export default SmartSaveDevices;

type SmartSaveDeviceProps = {
  device: SmartSaveDeviceType;
  deviceIndex: number;
};

const SmartSaveDevice = ({ device, deviceIndex }: SmartSaveDeviceProps) => {
  const { devices } = useSelector(selectSite);
  const { switches } = useGetSiteSwitches();
  const updateDevice = useUpdateSmartSaveDevice();
  const background = useColorModeValue('white', 'gray.700');

  const Icon = useMemo(() => {
    const isSwitch = !!switches.find((s) => s.id === device.site_device_id);

    // currently we only support EV_CHARGER and HOT_WATER
    // so, switch is hot water
    if (isSwitch) return CIRCUIT_TYPE_TO_ICON['load_hot_water'];

    const siteDevice = devices.find((d) => d.id === device.site_device_id);
    const manufacturerId = siteDevice?.manufacturer_id ?? 0;
    return getApplianceIcon(siteDevice ? 'load_ev_charger' : 'load_other', manufacturerId);
  }, [device, devices, switches]);

  return (
    <Center
      w={16}
      h={16}
      border={'1px solid'}
      borderRadius={'50%'}
      position={'relative'}
      onClick={() => updateDevice(device.site_device_id)}
      data-testid={`smart-save-device-${deviceIndex}`}
    >
      <Center
        position={'absolute'}
        top={-1}
        left={-1}
        w={6}
        h={6}
        border={'1px solid'}
        borderColor={'gray.400'}
        borderRadius={4}
        bg={background}
      >
        {device.active && (
          <Icon
            as={IoCheckmark}
            ml="auto"
            color="green.500"
            w={6}
            h={6}
            data-testid="smart-save-device-selected-checkmark"
          />
        )}
      </Center>
      <Icon w={6} h={6} />
    </Center>
  );
};
