import React, { useCallback } from 'react';
import { Image, useColorModeValue } from '@chakra-ui/react';

import { DeviceType } from 'clipsal-cortex-types/src/api';

import { getApplianceIcon } from '../live/power-flow/utils';
import { DEVICE_TYPE_TO_DISPLAY_DATA } from '../profile/system-details/constants';

const useGetActivityLogIcon = () => {
  const iconProperty = useColorModeValue('iconLight', 'iconDark');

  return useCallback(
    (deviceType: string, circuitType: string, manufacturerId: number) => {
      if (deviceType) {
        return (
          <Image
            src={DEVICE_TYPE_TO_DISPLAY_DATA[deviceType as DeviceType][iconProperty]}
            alt={'Device icon'}
            w={8}
            h={8}
            my="auto"
          />
        );
      }
      const CircuitIcon = getApplianceIcon(circuitType, manufacturerId);
      return <CircuitIcon w={8} h={8} my="auto" />;
    },
    [iconProperty]
  );
};

export default useGetActivityLogIcon;
