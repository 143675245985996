/**
 * Formats a raw numeric value to a readable currency string (including dollars and cents).
 *
 * @param value The value to convert
 */
export function formatDollarsCents(value: number): string {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
  };

  try {
    return new Intl.NumberFormat('en-US', options).format(value);
  } catch (e) {
    delete options.currencyDisplay;
    return new Intl.NumberFormat('en-US', options).format(value);
  }
}

/**
 * Calculates the ordinal suffix for a number.
 *
 * @param rank - The number to return the ordinal suffix for.
 */
export function getOrdinalSuffix(rank: number) {
  // Calculate rank modulo 10 and 100
  const modTen = rank % 10;
  const modHundred = rank % 100;

  if (modTen === 1 && modHundred !== 11) {
    return 'st';
  } else if (modTen === 2 && modHundred !== 12) {
    return 'nd';
  } else if (modTen === 3 && modHundred !== 13) {
    return 'rd';
  }

  return 'th';
}

/**
 * Formats a raw numeric value to a readable currency string (with only dollars, and no cents).
 * Note that this function will round values up/down respectively to the nearest integer.
 *
 * @param value
 */
export function formatDollarsNoCents(value: number): string {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  };

  try {
    return new Intl.NumberFormat('en-US', options).format(value);
  } catch (e) {
    delete options.currencyDisplay;
    return new Intl.NumberFormat('en-US', options).format(value);
  }
}

/**
 * Formats a raw numeric value as seconds to a string in the format of hh:mm:ss
 *
 * @param value - the number of seconds to format
 */
export function formatSecondsToHHMMSS(value: number) {
  let hours: number | string = Math.floor(value / 3600);
  let minutes: number | string = Math.floor((value - hours * 3600) / 60);
  let seconds: number | string = Math.round(value - hours * 3600 - minutes * 60);

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${hours}:${minutes}:${seconds}`;
}

/**
 * Formats a raw numeric value as seconds to a string in the format of hh:mm
 *
 * @param value - the number of seconds to format
 */
export function formatSecondsToHHMM(value: number) {
  let hours: number | string = Math.floor(value / 3600);
  let minutes: number | string = Math.floor((value - hours * 3600) / 60);

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;

  return `${hours}:${minutes}`;
}

/**
 * Formats a raw numeric value as seconds to a string in the format of h:mm
 *
 * @param value - the number of seconds to format
 */
export function formatSecondsToHMM(value: number) {
  const hours: number | string = Math.floor(value / 3600);
  let minutes: number | string = Math.floor((value - hours * 3600) / 60);

  if (minutes < 10) minutes = `0${minutes}`;

  return `${hours}:${minutes}`;
}

/**
 * Formats a raw numeric value as minutes to a string in the format of h:mm
 *
 * @param value - the number of seconds to format
 */
export function formatMinutesToHMM(value: number) {
  const hours: number | string = Math.floor(Math.abs(value) / 60);
  let minutes: number | string = Math.abs(value) % 60;
  if (minutes < 10) minutes = `0${minutes}`;

  return `${hours}:${minutes}`;
}
